var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.pageData.cashier,
                  expression: "pageData.cashier"
                }
              ],
              staticClass: "custom-col cashierBox",
              staticStyle: { width: "282px" }
            },
            [
              _c("v-text-field", {
                attrs: {
                  value: _vm.pageData.cashier,
                  label: "出纳审核",
                  "hide-details": "auto",
                  outlined: "",
                  dense: true,
                  height: 42,
                  readonly: ""
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { staticClass: "custom-col reasonBox" },
            [
              _c("v-textarea", {
                attrs: {
                  outlined: "",
                  height: 120,
                  label: "售后原因",
                  value: _vm.pageData.reason,
                  readonly: ""
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }