<template>
	<div>
		<el-row>
			<el-col class="custom-col cashierBox" v-show="pageData.cashier" style="width: 282px">
				<v-text-field
					:value="pageData.cashier"
					label="出纳审核"
					hide-details="auto"
					outlined
					:dense="true"
					:height="42"
					readonly
				/>
			</el-col>
		</el-row>
		<el-row>
			<el-col class="custom-col reasonBox">
				<v-textarea
					outlined
					:height="120"
					label="售后原因"
					:value="pageData.reason"
					readonly
				></v-textarea>
			</el-col>
		</el-row>
	</div>
</template>

<script>
export default {
	props: {
		pageData: Object
	}
}
</script>

<style lang="scss" scoped>
.cashierBox {
	display: flex;
	font-size: 14px;
	color: #333;
	margin-bottom: 20px;
	.cashier {
		margin-left: 50px;
	}
}
.reasonBox {
	display: flex;
	// width: 1100px;
	.reasonTitle {
		font-size: 14px;
		margin-right: 25px;
		color: #333;
	}
	.reasonContent {
		flex: 1;
		border: 1px solid #E1E4EB;
		padding: 10px;
		color: #333;
		border-radius: 6px;
		font-size: 16px;
		height: 100px;
		// overflow-y: scroll
		word-break: break-all;
		word-wrap: break-word;
	}
}
</style>